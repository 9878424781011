* { box-sizing: border-box; }

.layout404 {display: flex; position: fixed; z-index: 9999; top:0; left: 0; width: 100%; height: 100%;}
.pageCntBlock { padding: 0; list-style: none; display: flex; align-items: center; justify-content: center; position: relative; top: 0; flex-direction: column; flex: 1; background-color: rgba(0,0,0,0.64);}
.pageCntBlock.background {background-color: #fff;}
.pageCntPopup {display: flex; flex-direction: column; background-color: #fff; border-radius: 4px; padding:16px; align-items: center; min-width:240px; max-width: 480px; margin:16px;
    text-align: center;}
.pcIconBox {border-radius: 4px; display: flex; flex: 0 0 auto; align-items: center; justify-content: center; margin-bottom: 16px; }
.pcIconBox.Lg { width: 256px; height: 256px;}
.pcIconBox.Md { width: 104px; height: 104px;}
.pcIconBox img { width: 100%; height: 100%; }
.pcIconBox.lg img { width: 64px; height: 64px; }
.pageCntHdng { font-size: 1.5rem; font-weight: 400; margin-bottom: 8px; }
.txtMd { font-size: 1.0rem; font-weight: 400; color: rgba(0,0,0,0.54); }
.pageAction {display: flex; margin-top: 16px;}

.mrgnT { margin-top: 80px; }
.flexOne {flex: 1;}