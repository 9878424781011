.selectInput {
  position: relative;
  border-radius: 4px;
  margin: 2.5px 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  min-width: 130px;
  justify-content: center;
}

.selectInput label {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--whiteColor);
  color: rgba(0, 0, 0, 0.38);
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: .1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.selectInput select {
  font-size: 1rem;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  background-color: var(--whiteColor);
  padding: 7px;
  color: rgba(0, 0, 0, 0.87);
  transition: 0.1s ease-out;
  text-transform: capitalize;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  line-height: 1.5;
}

.selectInput select:focus {
  border-color: var(--primaryColor);
}

.selectInput select:focus+label {
  color: var(--primaryColor);
  font-size: 0.875rem;
  top: 0;
  transform: translateY(-50%) scale(.9);
}

.selectInput select:not(:placeholder-shown)+label {
  top: 0;
  font-size: 0.875rem;
  transform: translateY(-50%) scale(.9);
}

.selectInput:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  left: unset;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}

/**** Arabic *****/
body.rtl .selectInput:after {
  left: 10px;
  right: unset;
}

.selectFullWidth {
  width: 100%
}

;

.Sm {
  width: 58px;
}

.selectInput select>option {
  padding: 12px 16px;
  line-height: 48px;
  cursor: pointer;
  color: black;
}

.selectInput select>option:hover {
  background-color: rgba(0, 0, 0, 0.04);
  text-decoration: none;
}

.selectErrorMsg {
  display: flex;
  padding: 0 !important;
  color: red;
  font-size: 12px;
}

/* special requirement-for patient sign-up */
.selectTitalErrorMsg {
  background-color: rgba(255, 255, 255, 0.38);
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px 12px;
  font-weight: 500;
  line-height: 1.2;
  display: flex;
  color: red;
  font-size: 0.75rem;
  /* title arrow postion */
  position: absolute; 
  top: 40px;
  left: 0;
}

@media (min-width: 240px) and (max-width: 539px) {
  .selectInput {
    position: relative;
    border-radius: 4px;
    margin: 2.5px 0;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    min-width: 130px;
    max-width: 270px !important;
    justify-content: center;
  }
  

}
