.innerMainContainer {
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: var(--baseColor);
    padding-top: 56px;
    /* overflow-y: auto; */
    scroll-behavior: smooth;
}

/* DESKTOP LARGE DEVICES */
@media (min-width: 1367px) {
    .innerMainContainer {
        padding-top: 64px;
    }
}
