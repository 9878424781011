/* VARIABLES */
:root {
    --primaryColor: #1c9f67;
    /* --primaryColor: #3a8081; */

    --primaryLColor: #f0f7f7;
    --primaryLDColor: #cfe6e6;
    --primarySLight: #dae6e6;
    --primaryDark: #275758;
    /* --secondaryColor: #2b80c4; */
    --secondaryColor: #fb8427;
    --successColor: #008f00;
    --linkColor: #358fd0;
    --whiteColor: #ffffff;
    --whiteLColor: rgba(255, 255, 255, 0.7);
    --dangerColor: #bc0c00;
    --redColor: #ff2600;
    --dangerSuperLight: #fdede8;
    --outLinkColor: #00ffff;
    --baseColor: #f8f9fb;
    --baseLColor: #eeeff1;
    --baseDColor: #e5e9f2;
    --confBgColor: #040808;
    --confFtrColor: #070b0b;

    --brdrBtm: 1px solid rgba(0, 0, 0, 0.12);
    --bgGradient: linear-gradient(to bottom, var(--whiteColor), var(--baseColor));

    --txtBlack: rgba(0,0,0,0.87);
    --txtBlackL: rgba(0,0,0,0.56);
}
* {
    box-sizing: border-box;
}
html,
body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    color: var(--txtBlack);
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body.rtl {
    font-family: -apple-system, BlinkMacSystemFont, "DroidKufiRegular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}
.rtl {
    direction: rtl;
    text-align: right;
}


/* RESET */
p,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
a {
    padding: 0;
    margin: 0;
    text-decoration: none;
}
ul,
li {
    list-style-type: none;
}
button {
    outline: none;
    border: none;
    box-shadow: none;
}

/* HEADING TEXTS */
.headingTxt {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--whiteColor);
}

/* IMAGE BG */
.invertBlack {
    -moz-filter: brightness(0) invert(0);
    -webkit-filter: brightness(0) invert(0);
    filter: brightness(0) invert(0);
}
.invertWhite {
    -moz-filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}
.invertGray {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.invertWhiteInactive {
    -moz-filter: brightness(0) invert(0.54);
    -webkit-filter: brightness(0) invert(0.54);
    filter: brightness(0) invert(0.54);
}
/* Styling Images,Icons */
.iconStyle {
    width: 60%;
}

#root,
.App,
.App-div {
    width: 100%;
    height: 100%;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(195, 193, 193); 
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(183, 181, 181); 
  }

.linkTxt {
    color: var(--primaryColor);
    font-size: 1rem;
    cursor: pointer;
}
.linkTxt.linkTxtLg {
    font-size: 1.1rem;
    font-weight: 550;
}
.linkTxt.linkTxtMd {
    font-size: 1rem;
    font-weight: 550;
}
.linkTxt.linkTxtSm {
    font-size: 0.875rem;
    font-weight: 550;
}

.fullTxtLink {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: inherit;
    color: var(--primaryColor);
}
.fullTxtLink img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex: 0 0 auto;
    display: flex;
}
.fullTxtLink span {
    font-size: 1rem;
    font-weight: 500;
    flex: 0 0 auto;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.errorBox {
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    padding: 8px;
    align-items: center;
    justify-content: center;
    background-color: var(--dangerSuperLight);
    margin-bottom: 8px;
    border-radius: 4px;
}
.errorTxt {
    font-size: 0.875rem;
    color: var(--dangerColor);
    word-break: break-all;
    line-height: 24px;
}
.txtCenter {
    text-align: center;
}
.txtRight {
    text-align: right;
}
.redTxt {
    color: var(--dangerColor);
}
.successTxt {
    color: var(--successColor);
}
.pendingTxt {
    color: #fb8427;
}
.inpLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    margin: 10px 0 4px 0;
    display: block;
    text-align: left;
}
.PhoneInput {
    width: inherit;
}
.customPkgInput {
    background-color: #fff;
    border-radius: 4px;
    padding: 0 0 0 12px;
    border: 1px solid rgba(0, 0, 0, 0.24);
}
.customPkgInput input[type="tel"] {
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: unset;
}
.customPkgInput input[type="tel"]:focus {
    border-color: var(--primaryColor);
}
.smallPTxt {
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.54);
    word-break: break-word;
}
.primaryTxt {
    color: var(--primaryColor);
}

/*** P Text ***/
.pTxt {
    font-size: 1rem;
    color: var(--whiteColor);
    line-height: 22px;
}
.txt{
    font-size: 1rem;
    color: var(--confBgColor);
    line-height: 22px;

}
.pTxtPrev {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
    line-height: 22px;
}
.topHdngTxt {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 400;
}
.pTxtFlx {
    display: flex;
    flex: 1;
}
.subHdng {
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 22px;
}
.signupTxtClr{
    color: #0fe78bd9;
}
.pTxtSm {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.54);
    line-height: 1.25rem;
}
/* Text Decoration */
.txtUl {
    text-decoration-line: underline;
    text-decoration-style: solid;
}

/* HANLDE EXTRA */
.childRight {
    justify-content: flex-end;
}
.spaceBtvn {
    justify-content: space-between;
}
.alignRight {
    align-self: flex-end;
}
.alignCenter {
    align-items: center !important;
    display: flex;
}
.alignStart {
    align-items: flex-start !important;
    display: flex;
}
.justifyCenter {
    justify-content: center;
}
.mrgTop8 {
    margin-top: 8px;
}
.mrgTop16 {
    margin-top: 16px;
}
.mrgTop24 {
    margin-top: 24px;
}
.mrgTop32 {
    margin-top: 32px;
}
.mrgTB40 {
    margin: 40px 0;
}
.mrgBtm16 {
    margin-bottom: 16px !important;
}
.mrgRight8 {
    margin-right: 8px !important;
}
.mrgRight16 {
    margin-right: 16px !important;
}
.mrgLeft8 {
    margin-left: 8px !important;
}
.overRightjtcCenter {
    justify-content: center !important;
}
.overRightFlexone {
    flex: 0 0 auto !important;
}
.whiteTxt {
    color: var(--whiteColor) !important;
}
.whiteInactiveTxt {
    color: rgba(255, 255, 255, 0.72);
}
.blackInactiveTxt {
    color: rgba(0, 0, 0, 0.54);
}
.fullHightJC {
    height: 100%;
    justify-content: center;
}
.mrgnVAuto {
    margin: auto 0;
}
.mrgnLMD{
    margin-left: 16px;
}
.mrgnRMD{
    margin-right: 16px;
}
.whiteBg {
    background-color: var(--whiteColor);
}
.fullWidth {
    width: 100% !important;
}
.noBrdr {
    border: none !important;
}
.noBg {
    background-color: unset !important;
}
.flexZA {
    flex: 0 0 auto !important;
    display: flex;
}
.pdngTB16 {
    padding: 16px 0 !important;
}
.pdngTB8 {
    padding: 8px 0 !important;
}
.pdngBtm16 {
    padding-bottom: 16px !important;
}
.pdngTSM{
    padding-top: 0.5rem;
}
.pdngTBLR16 {
    padding: 16px !important;
}
.nopdng {
    padding: 0 !important;
}
.heightNone {
    height: none;
}
.respFlexPos {
    justify-content: flex-end;
}
.respFlexPos button:first-child {
    order: 1;
}
.noHover {
    background-color: transparent !important;
}
/* row and col blocks */
.rowBlock {
    display: flex;
    flex: 0 0 auto !important;
}
.colBlock {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
}
.txtLinkRowBlock {
    display: flex;
    flex: 0 0 auto;
    padding: 8px 0px;
    width: 100%;
}
.capitalizeTxt {
    text-transform: capitalize;
}
.Checkout.is-desktop .Header-logoImage {
    background-repeat: no-repeat;
    background-size: contain;
}
.flex0 {
    flex: 0 0 auto !important;
    display: flex;
}
.flex1 {
    flex: 1 1 auto !important;
    display: flex;
}

/** PADDINGS ***/
.pdTB16 {
    padding: 16px 0 !important;
}
.pdT16 {
    padding-top: 16px !important;
}
.pdLT16 {
    padding: 0 16px !important;
}

/* BODY SCROLL */
.noScroll {
    overflow: hidden;
}
/* UTILITY CLASSES */
.flexRow {
    display: flex;
    flex: none;
    width: 100%;
}
.flexCol {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 100%;
}
.flexWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
}
.flexInline {
    display: flex;
    flex: none;
}
.flexMinWidthRow {
    display: flex;
    flex: 1;
    min-width: 0;
}
.flexMinWidthCol {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}
.flexAutoRow {
    display: flex;
    flex: none;
    min-width: 24px;
}
.flexAutoCol {
    display: flex;
    flex: none;
    min-width: 24px;
    flex-direction: column;
}
.flexAuto {
    display: flex;
    flex: none;
    min-width: 1.5rem;
}
.flexMinHeightCol {
    display: flex;
    min-height: 0;
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.flexGrow {
    display: flex;
    flex-grow: 1;
}
.flexHalf {
    width: 100% !important;
}
.resColRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: none;
}
.resRowCol {
    display: flex;
    flex: none;
    flex-direction: column;
    width: 100%;
}
.pdngSM {
    padding: 0.5rem !important;
}
.pdngMD {
    padding: 1rem !important;
}
.pointer{
    cursor: pointer;
}
.wlcomText{font-size: 12px; text-align: end;color: var(--whiteColor);}
.imgaIconxs{width: 16px; height: 16px;}
.imgaIconxs img{width: 100%; height: 100%;}
.prfName{max-width: 120px;}
.pdngHMD{padding: 0 16px;}
.txtAlignEnd{text-align: end; text-transform: capitalize;}
.ellipsisTxt { width: 100%; display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.pdngHXSM{padding: 0 8px;}
@media (min-width: 1113px) and (max-width: 1366px) {
    .respFlexPos {
        justify-content: flex-start;
    }
    .respFlexPos button:first-child {
        order: 0;
    }
    .resColRow {
        flex-direction: row;
    }
    .resRowCol {
        flex-direction: column;
    }
}

@media (min-width: 1367px) {
    .respFlexPos {
        justify-content: flex-start;
    }
    .respFlexPos button:first-child {
        order: 0;
    }
    .resColRow {
        flex-direction: row;
    }
    .resRowCol {
        flex-direction: column;
    }
}

/* 
{
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
} */
