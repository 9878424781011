.pdngVXS{padding: 8px 0;}
.pdngXS{padding: 8px;}
.pdngTMD{padding: 16px 0;}
.pdngLXS{padding-left: 8px;}
.pdngLXXS{padding-left: 4px;}
.imgAvatar {width:24px; height: 24px;}
.imgAvatar img {width:100%; height: 100%;object-fit: cover;}
.imgAvatarXS {width:18px; height: 18px;}
.imgAvatarXS img {width:100%; height: 100%;object-fit: cover;}
.mediaPreview {position: fixed; z-index: 1299; left: 0; top: 0; background-color: #000000; width: 100%; height: 100%;}
.previewSlideItem { position: relative; width: 100%; height: 100%;  }
.previewSlideItem video { width: 100%; height: 100%; }
.previewSlideItem img { overflow: hidden;width: auto; object-fit: contain; height: initial;}
.edmImgCntr{ width: 100%; height: initial;}
.font14{font-size: 14px;}
.font10{font-size: 10px;}
.timeleft{color: #DE5327;}
.badge{    bottom: -4px !important;
    right: 18px !important;}
