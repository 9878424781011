@media (min-width: 240px) and (max-width: 767px) {
    .sideNav {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0px;
        left: 0;
        bottom: 0px;
        right: auto;
        height: 100%;
        width: 264px;
        -webkit-transform: translateX(-105%);
        -moz-transform: translateX(-105%);
        -ms-transform: translateX(-105%);
        -o-transform: translateX(-105%);
        transform: translateX(-105%);
        -webkit-transition: all 0.25s linear;
        -moz-transition: all 0.25s linear;
        transition: all 0.25s linear;
        z-index: 999999;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: var(--whiteColor);
    }
    .sideNav.slideIn {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.38);
    }
    .slideFade {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        -webkit-transition: all 0.15s ease-out 0s;
        -moz-transition: all 0.15s ease-out 0s;
        transition: all 0.15s ease-out 0s;
        opacity: 0;
        background: black;
        visibility: hidden;
        opacity: 0;
        z-index: 999998;
    }
    .slideFade.isVisible {
        opacity: 0.4;
        visibility: visible;
    }

    .sideNavBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        min-height: 0;
    }
    .sideNavInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: flex-start;
        border-bottom: var(--brdrBtm);
    }
    .sdnvHdr {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        padding: 0;
    }
    .sdnvClose {
        display: flex;
        cursor: pointer;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .sdnvClose:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(45deg);
    }
    .sdnvClose:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(-45deg);
    }
    .sideNavLinkGroup {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        padding: 8px 0;
    }

    .sdnvUsrInfo {
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
    }
    .sdnvUsrAvtr {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        border-radius: 50%;
    }
    .sdnvUsrAvtr span {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--whiteColor);
        text-transform: uppercase;
    }
    .sdnvUsrAvtr img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .sdnvTxtL {
        font-size: 1.125rem;
        margin: 6px 0;
        font-weight: 400;
    }
    .sdnvTxtS {
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
    }

    .sdnvLinkGroup {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;
    }
    .sdnvLinkItem {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        height: 48px;
        padding: 0 8px;
        align-items: center;
    }
    .sdnvLink {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0 16px;
        height: inherit;
    }
    .sdnvLinkImg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        flex: 0 0 auto;
        display: flex;
    }
    .sdnvLinkTxt {
        min-width: 0;
        flex: 1;
        flex-direction: column;
        padding: 0 8px;
    }
    .sdnvLinkTxt span {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sdnvCounter {
        min-width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        margin-left: 8px;
        padding: 0 4px;
    }
    .sdnvCounter small {
        font-size: 10px;
        color: var(--whiteColor);
    }
    .activeLink {
        background-color: var(--primaryLColor);
        border-radius: 4px;
    }
    .activeLink .sdnvLinkTxt span {
        color: var(--primaryColor);
    }

    .sdnvLinkGroup.btmGrp {
        flex: 0 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .webSideNav {
        display: none;
    }
}

/* TABS */
@media (min-width: 768px) and (max-width: 1112px) {
    .sideNav {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0px;
        left: 0;
        bottom: 0px;
        right: auto;
        height: 100%;
        width: 50%;
        -webkit-transform: translateX(-105%);
        -moz-transform: translateX(-105%);
        -ms-transform: translateX(-105%);
        -o-transform: translateX(-105%);
        transform: translateX(-105%);
        -webkit-transition: all 0.25s linear;
        -moz-transition: all 0.25s linear;
        transition: all 0.25s linear;
        z-index: 999999;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: var(--whiteColor);
    }
    .sideNav.slideIn {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.38);
    }
    .slideFade {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        -webkit-transition: all 0.15s ease-out 0s;
        -moz-transition: all 0.15s ease-out 0s;
        transition: all 0.15s ease-out 0s;
        opacity: 0;
        background: black;
        visibility: hidden;
        opacity: 0;
        z-index: 999998;
    }
    .slideFade.isVisible {
        opacity: 0.4;
        visibility: visible;
    }

    .sideNavBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        min-height: 0;
    }
    .sideNavInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: flex-start;
        border-bottom: var(--brdrBtm);
    }
    .sdnvHdr {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        padding: 0;
    }
    .sdnvClose {
        display: flex;
        cursor: pointer;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .sdnvClose:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(45deg);
    }
    .sdnvClose:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(-45deg);
    }
    .sideNavLinkGroup {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        padding: 8px 0;
    }

    .sdnvUsrInfo {
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
    }
    .sdnvUsrAvtr {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        border-radius: 50%;
    }
    .sdnvUsrAvtr span {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--whiteColor);
        text-transform: uppercase;
    }
    .sdnvUsrAvtr img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .sdnvTxtL {
        font-size: 1.125rem;
        margin: 6px 0;
        font-weight: 400;
    }
    .sdnvTxtS {
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
    }

    .sdnvLinkGroup {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;
    }
    .sdnvLinkItem {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        height: 48px;
        padding: 0 8px;
        align-items: center;
    }
    .sdnvLink {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0 16px;
        height: inherit;
    }
    .sdnvLinkImg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        flex: 0 0 auto;
        display: flex;
    }
    .sdnvLinkTxt {
        min-width: 0;
        flex: 1;
        flex-direction: column;
        padding: 0 8px;
    }
    .sdnvLinkTxt span {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sdnvCounter {
        min-width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        margin-left: 8px;
        padding: 0 4px;
    }
    .sdnvCounter small {
        font-size: 10px;
        color: var(--whiteColor);
    }
    .activeLink {
        background-color: var(--primaryLColor);
        border-radius: 4px;
    }
    .activeLink .sdnvLinkTxt span {
        color: var(--primaryColor);
    }

    .sdnvLinkGroup.btmGrp {
        flex: 0 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .webSideNav {
        display: none;
    }
}

/* TABS */
@media (min-width: 1113px) and (max-width: 1366px) {
    .sideNav {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0px;
        left: 0;
        bottom: 0px;
        right: auto;
        height: 100%;
        width: 50%;
        -webkit-transform: translateX(-105%);
        -moz-transform: translateX(-105%);
        -ms-transform: translateX(-105%);
        -o-transform: translateX(-105%);
        transform: translateX(-105%);
        -webkit-transition: all 0.25s linear;
        -moz-transition: all 0.25s linear;
        transition: all 0.25s linear;
        z-index: 999999;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: var(--whiteColor);
    }
    .sideNav.slideIn {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.38);
    }
    .slideFade {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        -webkit-transition: all 0.15s ease-out 0s;
        -moz-transition: all 0.15s ease-out 0s;
        transition: all 0.15s ease-out 0s;
        opacity: 0;
        background: black;
        visibility: hidden;
        opacity: 0;
        z-index: 999998;
    }
    .slideFade.isVisible {
        opacity: 0.4;
        visibility: visible;
    }

    .sideNavBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        min-height: 0;
    }
    .sideNavInfo {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-self: flex-start;
        border-bottom: var(--brdrBtm);
    }
    .sdnvHdr {
        display: flex;
        width: 100%;
        height: 56px;
        align-items: center;
        padding: 0;
    }
    .sdnvClose {
        display: flex;
        cursor: pointer;
        width: 48px;
        height: 48px;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .sdnvClose:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(45deg);
    }
    .sdnvClose:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 3px;
        background-color: var(--primaryColor);
        transform: rotate(-45deg);
    }
    .sideNavLinkGroup {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        padding: 8px 0;
    }

    .sdnvUsrInfo {
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
    }
    .sdnvUsrAvtr {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        border-radius: 50%;
    }
    .sdnvUsrAvtr span {
        font-size: 1.5rem;
        font-weight: 400;
        color: var(--whiteColor);
        text-transform: uppercase;
    }
    .sdnvUsrAvtr img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .sdnvTxtL {
        font-size: 1.125rem;
        margin: 6px 0;
        font-weight: 400;
    }
    .sdnvTxtS {
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
    }

    .sdnvLinkGroup {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;
    }
    .sdnvLinkItem {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        height: 48px;
        padding: 0 8px;
        align-items: center;
    }
    .sdnvLink {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0 16px;
        height: inherit;
    }
    .sdnvLinkImg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        flex: 0 0 auto;
        display: flex;
    }
    .sdnvLinkTxt {
        min-width: 0;
        flex: 1;
        flex-direction: column;
        padding: 0 8px;
    }
    .sdnvLinkTxt span {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.87);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sdnvCounter {
        min-width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        margin-left: 8px;
        padding: 0 4px;
    }
    .sdnvCounter small {
        font-size: 10px;
        color: var(--whiteColor);
    }
    .activeLink {
        background-color: var(--primaryLColor);
        border-radius: 4px;
    }
    .activeLink .sdnvLinkTxt span {
        color: var(--primaryColor);
    }

    .sdnvLinkGroup.btmGrp {
        flex: 0 0 auto;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    /* .webSideNav {display: none;} */
    .webSideNav {
        display: flex;
        position: fixed;
        top: 64px;
        left: 0;
        width: 264px;
        bottom: 0;
        background-color: var(--whiteColor);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        z-index: 1;
    }
    /**** Arabic *****/
    body.rtl .webSideNav {
        right: 0;
        left: initial;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    .webSideNav .wsnBlock {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex: 1;
    }
    .wsnBlock .wsnGroup {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        flex: 1;
        padding: 8px 0;
    }
    .wsnGroup .wsnItem {
        display: flex;
        flex: 0 0 auto;
        height: 48px;
        align-items: center;
    }
    .wsnItem .wsnLink {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0px 8px;
        height: 48px;
        cursor: pointer;
        margin: 0 8px;
        border-radius: 4px;
    }
    .wsnItem .wsnLink img {
        width: 16px;
        height: 16px;
        margin: 0 8px;
    }
    .wsnItem .wsnLink .linkTxtBlock {
        display: flex;
        padding: 0 8px;
        min-width: 0;
        flex: 1;
        overflow: hidden;
        flex-direction: column;
        border-radius: 4px;
    }
    .wsnItem .wsnLink .linkTxtBlock span {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.54);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .wsnItem .wsnLink .linkCounter {
        min-width: 24px;
        height: 24px;
        padding: 0 4px;
        display: flex;
        flex: 0 0 auto;
        background-color: var(--primaryColor);
        border-radius: 50px;
        align-items: center;
        justify-content: center;
    }
    .wsnItem .wsnLink .linkCounter span {
        font-size: 12px;
        color: var(--whiteColor);
        font-weight: 600;
    }
    .wsnItem .wsnLink:hover {
        background-color: var(--primaryLColor);
        transition: all 0.3s linear;
    }
    .wsnItem .wsnLink.wsnAcitve {
        background-color: var(--primaryLColor);
    }
    .wsnItem .wsnLink.wsnAcitve .linkTxtBlock span {
        color: var(--primaryColor);
    }

    .wsnGroup.btmGrp {
        flex: 0 0 auto;
        overflow: hidden;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
}

@media (min-width: 1367px) {
    .sideNav {
        display: none;
    }
    .slideFade {
        display: none;
    }

    .webSideNav {
        display: flex;
        position: fixed;
        top: 64px;
        left: 0;
        width: 264px;
        bottom: 0;
        background-color: var(--whiteColor);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        z-index: 1;
    }
    /**** Arabic *****/
    body.rtl .webSideNav {
        right: 0;
        left: initial;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
    .webSideNav .wsnBlock {
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex: 1;
    }
    .wsnBlock .wsnGroup {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        flex: 1;
        padding: 8px 0;
    }
    .wsnGroup .wsnItem {
        display: flex;
        flex: 0 0 auto;
        height: 48px;
        align-items: center;
    }
    .wsnItem .wsnLink {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 0px 8px;
        height: 48px;
        cursor: pointer;
        margin: 0 8px;
        border-radius: 4px;
    }
    .wsnItem .wsnLink img {
        width: 16px;
        height: 16px;
        margin: 0 8px;
    }
    .wsnItem .wsnLink .linkTxtBlock {
        display: flex;
        padding: 0 8px;
        min-width: 0;
        flex: 1;
        overflow: hidden;
        flex-direction: column;
        border-radius: 4px;
    }
    .wsnItem .wsnLink .linkTxtBlock span {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.54);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .wsnItem .wsnLink .linkCounter {
        min-width: 24px;
        height: 24px;
        padding: 0 4px;
        display: flex;
        flex: 0 0 auto;
        background-color: var(--primaryColor);
        border-radius: 50px;
        align-items: center;
        justify-content: center;
    }
    .wsnItem .wsnLink .linkCounter span {
        font-size: 12px;
        color: var(--whiteColor);
        font-weight: 600;
    }
    .wsnItem .wsnLink:hover {
        background-color: var(--primaryLColor);
        transition: all 0.3s linear;
    }
    .wsnItem .wsnLink.wsnAcitve {
        background-color: var(--primaryLColor);
    }
    .wsnItem .wsnLink.wsnAcitve .linkTxtBlock span {
        color: var(--primaryColor);
    }

    .wsnGroup.btmGrp {
        flex: 0 0 auto;
        overflow: hidden;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
}
